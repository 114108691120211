@import '../../../../customize/variables';

.container {
  padding-bottom: 20px;
  position: relative;
  font-size: $font-size-standard;
}

.image {
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px;
}

.title {
  font-size: 25px;
  font-weight: normal;
  margin: 10px 0;
}

.arrow {
  display: none;
}

.leftSide {
  padding-right: 10px;
  text-align: right;

  &.dateCircle:after {
    right: -20.5px;
  }
}

.dateCircle:after {
  background: none repeat scroll 0 0 $primary-color;
  border: 3px solid #ececec;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 7px;
  width: 15px;
  height: 15px;
  z-index: 20;
}

@media (min-width: $breakpoint-lg) {
  .image {
    max-width: 400px;
  }

  .arrow {
    display: block;
    position: absolute;
    top: 18px;

    &:after,
    &:before {
      border: medium solid transparent;
      content: ' ';
      position: absolute;
    }
  }

  .leftSide {
    padding-right: 70px;
    text-align: right;

    .arrow {
      right: 33px;

      &:after,
      &:before {
        right: 100%;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #fff;
        border-width: 6px;
        margin-top: -6px;
      }

      &:before {
        border-color: rgba(222, 3, 127, 0);
        border-right-color: $primary-color;
        border-width: 7px;
        margin-top: -7px;
      }
    }

    &.dateCircle:after {
      right: -10.5px;
    }
  }

  .rightSide {
    padding-left: 70px;
    text-align: left;

    .arrow {
      left: 33px;

      &:after,
      &:before {
        left: 100%;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #fff;
        border-width: 6px;
        margin-top: -6px;
      }

      &:before {
        border-color: rgba(222, 3, 127, 0);
        border-left-color: $primary-color;
        border-width: 7px;
        margin-top: -7px;
      }
    }

    &.dateCircle:after {
      left: -10.5px;
    }
  }
}
