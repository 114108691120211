@import '../../../customize/variables';

.section {
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.title {
  font-family: $font-primary-heading;
  font-weight: normal;
  font-size: 40px;
  // margin: 15px 0;

  i {
    transform: translateY(10px);
    font-size: 0.8em;
    color: $heart-color;
  }
}

.subsectionWrapper {
  min-height: 300px;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (min-width: $breakpoint-lg) {
    flex: 0 0 70%;
    max-width: 100%;
  }
}
