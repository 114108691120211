@import '../../../../customize/variables';

.wrapper {
  flex: 0 0 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: $font-size-standard;
}

.locationTitle {
  font-family: $font-heading;
  font-weight: normal;
  font-size: $font-size-heading;
  margin: 0;
  padding: 0 15px;
  display: inline;
  border-bottom: 3px solid;
  border-color: transparent;

  &.hovered {
    border-color: $primary-color;
  }

  i {
    margin-right: 15px;
    color: $primary-color;
  }
}

@media (min-width: $breakpoint-md) {
  .wrapper {
    flex: 0 0 50%;
    padding: 30px 20px;
  }
}
