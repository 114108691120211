@import '../../../customize/variables';

.section {
  margin: 20px;
  box-sizing: border-box;
}

.sectionTextWrapper {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
}

.sectionHeading {
  padding-top: 30px;
  padding-bottom: 15px;

  h2 {
    font-family: $font-heading;
    font-weight: normal;
    font-size: $font-size-heading;
    margin: 0;
  }
}

.sectionSeparator {
  padding: 10px 0;
}

.sectionHeading:after,
.sectionSeparator:after {
  content: '';
  background: url('../../../images/separator.png') no-repeat center center;
  height: 65px;
  display: block;
}

.sectionText {
  line-height: 1.5em;
  font-size: $font-size-standard;

  h2,
  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 24px;
  }

  ul {
    display: inline-block;
    text-align: left;
    margin: 0;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
