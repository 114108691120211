@import '../../../customize/variables';

.section {
  position: relative;
  z-index: 1;
  background-image: url(../../../images/full-width-image.jpg);
  background-size: cover;
  color: #fff;
  padding: 50px 20px;
  box-sizing: border-box;
  margin-top: 40px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
  }
}

.sectionTextWrapper {
  width: 100%;
  max-width: 740px;
  margin: 50px auto;
}

.sectionHeading {
  font-family: $font-heading;
  font-weight: normal;
  font-size: $font-size-heading;
  margin: 0;
  overflow: auto;
}

.sectionText {
  // line-height: 1.5em;
  font-size: $font-size-large;

  a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .section {
    padding: 70px 20px;
  }
}
