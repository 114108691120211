@import '../../../customize/variables';

.section {
  position: relative;
  z-index: 1;
  background-image: url(../../../images/full-width-image.jpg);
  background-size: cover;
  color: #fff;
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
  }
}

.subsectionWrapper {
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0 0 50px;
  box-sizing: border-box;

  &:last-child {
    padding: 0;
  }
}

.subsectionHeading {
  font-family: $font-heading;
  font-weight: normal;
  font-size: $font-size-heading;
  margin: 0;
  overflow: auto;
}

.subsectionText {
  line-height: 1.5em;
  font-size: $font-size-large;
}

.formModalIframe {
  max-width: 100%;
  height: 100%;
}

@media (min-width: $breakpoint-lg) {
  .section {
    padding: 70px 20px;
  }
}

@media (min-width: $breakpoint-xl) {
  .subsectionWrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 100px !important;
  }
}
