
@font-face {
  font-family: 'Antique';
  src: url('./../fonts/Montserrat Alternates.ttf') format('truetype');
}

@font-face {
  font-family: 'Pompadur';
  src: url('./../fonts/Pompadur.ttf') format('truetype');
}


$primary-color: #890f3a;
$heart-color: $primary-color;

$map-marker-color: $primary-color;
$map-marker-border-color: #000000;
$map-marker-hover-color: $map-marker-border-color;
$map-marker-hover-border-color: $map-marker-color;

$button-bg-color: $primary-color;
$button-text-color: #ffffff;
$button-border-color: transparent;
$button-hover-bg-color: #440018;
$button-hover-text-color: $button-text-color;
$button-hover-border-color: $button-bg-color;

//$font-standard: 'Raleway', sans-serif;
$font-standard: 'Antique';
//$font-primary-heading: 'Great Vibes', cursive;
$font-primary-heading: 'Pompadur';
$font-heading: $font-primary-heading;
$font-size-standard: 15px;
$font-size-large: 18px;
$font-size-heading: 35px;

$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
