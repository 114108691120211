@import '../../../../customize/variables';

.wrapper {
  width: 100%;
  max-width: 287px;
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.instagramWallIframe {
  border: 0;
  height: 267px;
  width: 1400px;
  margin-left: 7px;
}

@media (min-width: $breakpoint-lg) {
  .wrapper {
    max-width: 850px;
  }
}
